import React, { useContext, useEffect } from "react";
import { Image, Container } from "react-bootstrap";
import { ShopContext } from "../context/shopContext";
import CarousalComponent from "../components/Carousal/CarousalComponent";

import Jumbotrons from "../components/Jumbotrons/Jumbotrons";

import ProductParent from "../components/ProductParent/ProductParent";

import DiscoverMore from "../components/DiscoverMore/DiscoverMore";
import VideoSection from "../components/VideoSection/VideoSection";

function Home() {
  const { fetchAllProducts, products } = useContext(ShopContext);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <>
      {/* Carousal */}
      <CarousalComponent />
      <div
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        asd
      </div>
      <DiscoverMore />
      <Container>
        <Jumbotrons />
        <Image src="images/dividers/divider.jpg" className="mt-2" fluid />
        {/* <Categories /> */}
      </Container>
      <VideoSection />

      <ProductParent products={products} />
      {/* <Container>
        <ShopByInstagram />
        <CarousalComponent2 />
      </Container>
      <EzokAdvantage /> */}
      {/* <Container>
        <Image src="images/dividers/blog.jpg" className="mt-2" fluid />
        <Blogs />
      </Container> */}
      {/* <FooterTop /> */}
      <Image src="images/footer/footer.jpg" fluid></Image>
    </>
  );
}

export default Home;
