import React, { useContext, useState, useEffect } from "react";
import { Nav, Navbar, Container, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ShopContext } from "../../context/shopContext";
import "../Header/Header.css";

import { Menu as MenuIcon } from "@material-ui/icons";

function Header() {
  const [offset, setOffset] = useState(0);
  const { openCart } = useContext(ShopContext);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  console.log(offset);

  return (
    <header>
      <Navbar
        className={offset < 300 ? "nav-bg" : "nav-bg2"}
        expand="lg"
        collapseOnSelect
        fixed="top"
      >
        <Container style={{ maxWidth: "95%" }}>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image
                src="images/logo/logoF.png"
                alt="img"
                fluid
                width="120px"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div id="wrap">
                <form action="">
                  <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder="What're you looking for ?"
                    autoComplete="off"
                  />
                  <input id="search_submit" value="Rechercher" type="submit" />
                </form>
              </div>

              <Nav.Link>
                <MenuIcon onClick={() => openCart()} />
                &nbsp; MENU
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
