import React from "react";

function VideoSection() {
  return (
    // <div>
    //   <Image
    //     src="images/banners/video.jpg"
    //     fluid
    //     onClick={() => setOpen(true)}
    //   />
    //   <ModalVideo
    //     channel="custom"
    //     autoplay
    //     allowFullScreen
    //     isOpen={isOpen}
    //     url="https://apppl.com/video/video-res.mp4"
    //     onClose={() => setOpen(false)}
    //   />
    // </div>
    <div className="video-responsive">
      <video loop autoPlay muted style={{ width: "100%", height: "auto" }}>
        <source src="images/banners/video.mp4" type="video/mp4" />
      </video>
    </div>
  );
}

export default VideoSection;
