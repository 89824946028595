import React from "react";
import "../CarousalEffect/CarousalEffect.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination]);

function CarousalEffect() {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        pagination={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="images/banners/shoes/1.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="images/banners/shoes/3.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="images/banners/shoes/4.jpeg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://cdn.shopify.com/s/files/1/0570/6370/2695/products/2030-Black-DSC01485.jpg?v=1627379636"
            alt=""
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default CarousalEffect;
