import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../components/Product/Product.css";

function Product({ product }) {
  return (
    <>
      <Card>
        <Card.Img
          variant="top"
          src={product.images[0].src}
          className="hoverProducts"
        />
        <Card.Body style={{ background: "#e6e6e6", padding: ".6rem 1rem" }}>
          <Row>
            <Col style={{ padding: "2px" }}>
              <Card.Text
                className="m-0"
                style={{ fontFamily: "sans-serif", fontSize: "0.8em" }}
              >
                {product.title}
              </Card.Text>
              <Card.Text
                className="m-0"
                style={{ fontFamily: "sans-serif", fontSize: "0.8em" }}
              >
                <strong>₹{product.variants[0].price}</strong>
              </Card.Text>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "2px",
              }}
            >
              <Link
                // to={`product/${product.id}`}
                className="btn btn-outline-dark px-2 py-0"
                style={{
                  borderRadius: "0",
                  fontFamily: "sans-serif",
                  fontSize: "0.8em",
                }}
              >
                BUY NOW
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default Product;
