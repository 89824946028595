import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Product from "../Product/Product";
import "../../components/ProductParent/ProductParent.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Scrollbar } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar]);

function ProductParent({ products }) {
  return (
    <>
      {/* <Row className="my-5">
        <Col>
          {!products ? (
            "Loading"
          ) : (
            <Row style={{ width: "80%", margin: "auto" }}>
              {products.slice(0, 5).map((product) => {
                return (
                  <Col key={product.id}>
                    <Product product={product} />
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
      </Row> */}
      <Container className="py-5">
        <Row className="text-center">
          <Col>
            <h3>EZOK New Arrivals</h3>
          </Col>
        </Row>
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          scrollbar={{ draggable: true }}
          className="newArrivals"
          navigation={{ clickable: true }}
          grabCursor={true}
        >
          {products.map((product) => {
            return (
              <SwiperSlide key={product.id}>
                <Product product={product} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </>
  );
}

export default ProductParent;
