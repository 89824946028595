import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../DiscoverMore/DiscoverMore.css";

function DiscoverMore() {
  return (
    <section className="py-5">
      <div
        style={{
          backgroundImage: `url("images/Discover/pattren.png")`,
          backgroundSize: "contain",
          width: "100%",
          height: "900px",
          position: "absolute",
          zIndex: "-1",
          marginTop: "10%",
        }}
      ></div>
      <Container>
        <Row
          style={{ width: "80%", margin: "auto", backgroundColor: "white" }}
          className="pb-4 px-4"
        >
          <Col className="col1Div">
            <Image src="images/Discover/1.jpg" fluid className="hover1E" />
          </Col>
          <Col className="col1Div">
            <Image src="images/Discover/2.jpg" fluid className="hover1E" />
          </Col>
        </Row>
        <Row
          style={{ width: "80%", margin: "auto", backgroundColor: "white" }}
          className="pb-4 px-4"
        >
          <Col className="col1Div">
            <Image src="images/Discover/3.jpg" fluid className="hover1E" />
          </Col>
          <Col className="col1Div">
            <Image src="images/Discover/4.jpg" fluid className="hover1E" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DiscoverMore;
