import "../Carousal/Carousal.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import CarousalEffect from "../CarousalEffect/CarousalEffect";
import { List, ListItem, ListItemText } from "@material-ui/core";

function CarousalComponent() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url("images/banners/Ezok Bannerc.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "left",
          height: "100vh",
          width: "100%",
          position: "absolute",
          top: "0",
        }}
      >
        <CarousalEffect />
        <div className="customListDiv">
          <List
            className="customList"
            component="nav"
            aria-label="main mailbox folders"
          >
            <ListItem button>
              <ListItemText primary=" — Flexi Shoes" />
            </ListItem>
            <ListItem button>
              <ListItemText primary=" — Dress Shoes" />
            </ListItem>
            <ListItem button>
              <ListItemText primary=" — Comfort Shoes" />
            </ListItem>
            <ListItem button>
              <ListItemText primary=" — Moccasins" />
            </ListItem>
          </List>
        </div>
      </div>
    </>
  );
}

export default CarousalComponent;
